export default {
    methods: {
        fetchRealization(uuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/sale/realization/${uuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('realization.fetching-error'))
                        reject(err)
                    })
            })
        }
    }
}
